import axios from "axios";
import store from "../store/index";
import router from "@/router";

const instance = axios.create({
  baseURL: "/api",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  async (request) => {
    request.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "healex-token",
    )}`;
    return request;
  },
  (error) => {
    Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    // Verificar si la respuesta y el estado están definidos
    if (response && response.status) {
      // Si el token está caducando, refrescar el token
      if (response.status === 202) {
        store.dispatch("REFRESH_TOKEN");
      }
    }
    return response;
  },
  (error) => {
    // Verificar si la respuesta y el estado están definidos
    if (error.response && error.response.status) {
      // Verificar problemas de autenticación y cerrar sesión
      if (error.response.status === 401 && error.config.url !== "/status") {
        store.dispatch("ExpireSession");
        store.dispatch("LOGOUT");
        router.push("/login");
      } else {
        store.dispatch("SHOW_MESSAGE", {
          ...error.response.data,
          variant: "danger",
        });
      }
    }
    return Promise.reject(error);
  },
);

export default instance;

export class API {
  constructor() {
    this._axios = instance;
  }
  get axios() {
    return this._axios;
  }
  set axios(axios) {
    this._axios = axios;
  }

  setHeaderToken(token) {
    this.axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  finishSesssions() {
    return this.axios.put("/finish-sessions");
  }
  login({ UserName, Password }) {
    return this.axios.post("/Login", {
      UserName,
      Password,
    });
  }
  logout() {
    return this.axios.put("/logout");
  }
  smsLogin(token) {
    const auth = `Bearer ${token}`;
    return this.axios.post(
      `/login/sms?smsToken=${auth}`,
      {},
      {
        headers: {
          Authorization: auth,
        },
      },
    );
  }
  smsLink(token) {
    const auth = `Bearer ${token.token}`;
    return this.axios.put(
      `/user/link-sms?smsToken=${auth}}`,
      {},
      {
        headers: {
          Authorization: auth,
        },
      },
    );
  }
  getCase({ idCase }) {
    return this.axios.get(`/cases/${idCase}`);
  }

  getMasterForm(idOrganizationalUnit) {
    return this.axios.get(`/master-forms/${idOrganizationalUnit}`);
  }

  getAllEnablesOU() {
    return this.axios.get(`/OrganizationalUnit/UserBelongs`);
  }

  getSalaryTypes() {
    return this.axios.get(`/salaries/types?onlyEnabled=true`);
  }

  getSalaries(idSalaryType) {
    return this.axios.get(`/salaries/salary-type/${idSalaryType}`);
  }

  getSeniority(idSalaryType) {
    return this.axios.get(
      `/administrative-cost/seniority/salary-type/${idSalaryType}`,
    );
  }

  getAllGeneralSummations() {
    return this.axios.get(`/customer/general-summations`);
  }

  getCategoriesSalaries(idSalaryType) {
    return this.axios.get(`/categories/salary-type/${idSalaryType}`);
  }

  updateSalaries({ idSalaryType, salaries }) {
    return this.axios.post(`/salaries/salary-type/${idSalaryType}`, salaries);
  }
  ChangePassword({ userName, password }) {
    return this.axios.put(`/LogIn/ChangePassword`, {
      UserName: userName,
      Password: password,
    });
  }

  GetCustomers({ bEnable }) {
    const params = { enable: bEnable };
    return this.axios.get(`/customers`, { params });
  }

  createGeneralSummationCaseTemplate(generalSummation) {
    return this.axios.post(`/case-template/general-summation`, {
      idCaseTemplate: generalSummation.idCaseTemplate,
      idGeneralSummation: generalSummation.idGeneralSummation,
      showSubcases: false,
      showBudget: false,
    });
  }

  //
  UpdateTemplate(idOrganizationalUnit, data, summaryFile) {
    return this.axios.put(`/master-form/${idOrganizationalUnit}`, {
      file: data,
      summaryFile: summaryFile,
    });
  }

  updateGeneralSummations(
    idCaseTemplate,
    idGeneralSummation,
    showSubcases,
    showBudget,
  ) {
    return this.axios.put(
      `case-template/${idCaseTemplate}/general-summation/${idGeneralSummation}`,
      {
        showSubcases,
        showBudget,
      },
    );
  }

  deleteGeneralSummations(idCaseTemplate, idGeneralSummation) {
    return this.axios.delete(
      `case-template/${idCaseTemplate}/general-summation/${idGeneralSummation}`,
    );
  }

  getCaseState(idOrganizationalUnit, idCase) {
    return this.axios.get(`/cases/status/${idCase}`);
  }

  ChangeState(idOrganizationalUnit, idCase, status) {
    return this.axios.put(`/cases/status/${idCase}`, {
      status: status,
    });
  }

  Register(FullName, Mail, Comments) {
    return this.axios.post(`/LogIn/Register`, { FullName, Mail, Comments });
  }

  PasswordRecovery(userMail) {
    return this.axios.put(`/User/RecoveryPassword?userMail=${userMail}`);
  }

  ChangeCurrentEditor(idOrganizationalUnit, idCase, currentEditor) {
    return this.axios.put(`/cases/current-editor/${idCase}`, {
      NewOwner: currentEditor,
    });
  }
  //User/GetUsers?enabled=true&idOU=1
  GetUsers({ enabled, idOU }) {
    return this.axios.get("/users", {
      params: {
        enabled,
        idOU,
      },
    });
  }
  //User/xarm?idOU=85
  GetUser({ userName }) {
    return this.axios.get(`/user`, {
      params: {
        userName,
      },
    });
  }
  //api/User/{userName}
  UpdateUser({ user }) {
    return this.axios.put(`/User/${user.userName}`, user);
  }
  //api/User/CreateUser?idOU=85
  CreateUser({ user }) {
    return this.axios.post(`/User`, user);
  }

  //User/DeleteUser/xarm User
  DeleteUser({ userName }) {
    return this.axios.delete(`/User/${userName}`);
  }

  DeletePermanentlyUser({ userName }) {
    return this.axios.delete(`/user/${userName}/delete`);
  }

  VerifyUserCases({ userName }) {
    return this.axios.get(`/user/${userName}/cases`);
  }

  //User/ActiveUser/xarm User?idOU=85https://localhost:44321/api/User/ActiveUser/xarm User?idOU=85
  ActiveUser({ userName }) {
    return this.axios.put(`/User/${userName}/Active`);
  }

  // /User/{user}/OrganizationalUnits/Roles?idOURol=10
  UserRoles({ idOURole, userName }) {
    return this.axios.get(`/User/${userName}/OrganizationalUnits/Roles`, {
      params: {
        idOURole,
      },
    });
  }
  //api/User/{userName}/OrganizatinalUnit/{idOrganizationalUnit}/Role
  AddRole({ userName, idOURol, idOU, idRole }) {
    return this.axios.post(
      `/User/${userName}/OrganizatinalUnit/${idOU}/Role`,
      {
        Role: idRole,
      },
      {
        params: {
          idOURol,
        },
      },
    );
  }

  RemoveRole({ userName, idOURole, idOU }) {
    return this.axios.delete(
      `/User/${userName}/OrganizatinalUnit/${idOU}/Role`,
      {
        params: {
          idOURole,
        },
      },
    );
  }

  // /User/{user}/OrganizationalUnits/Roles?idOURol=10
  GetOrganizationalUnits({ idCustomer }) {
    return this.axios.get(`/Customer/${idCustomer}/OrganizationalUnits`);
  }

  //User/ResetPassword
  ResetPassword({ Name }) {
    return this.axios.put(`/User/${Name}/ResetPassword`);
  }

  //user/reset-password
  SelfResetPassword() {
    return this.axios.put(`/user/reset-password`);
  }

  ///api/User/OrganizationalUnit/{idOU}/Roles
  getAvailableRoles({ idOU }) {
    return this.axios.get(`/User/OrganizationalUnit/${idOU}/Roles`);
  }

  GetStudyTypes(enabled) {
    return this.axios.get(`/Customer/Surcharge/Conditions?enabled=${enabled}`);
  }

  GetSurcharges(idCondition) {
    return this.axios.get(`/Customer/Surcharge/Condition/${idCondition}`);
  }

  getUsersOU(idOU) {
    return this.axios.get(`/OrganizationalUnit/${idOU}/Users?onlyEnabled=true`);
  }

  ChangeCaseOwner(caseInfo, newOwner) {
    return this.axios.put(`/cases/assigned-user/${caseInfo.idCase}/`, {
      name: newOwner,
    });
  }

  UpdateConditionValues(idCondition, values) {
    return this.axios.put(
      `/Customer/Surcharge/Condition/${idCondition}/Definitions/Values`,
      values,
    );
  }

  //LogIn/SetLanguage?culture=de
  SetLanguage({ culture }) {
    return this.axios.put(`/LogIn/SetLanguage?culture=${culture}`);
  }

  //OrganizationalUnit/Settings
  Settings() {
    return this.axios.get(`/OrganizationalUnit/Settings`);
  }

  GetSubcaseState(idSubcase) {
    return this.axios.get(`/subcases/${idSubcase}/status`);
  }

  PostSubcase(
    caseName,
    file,
    idOrganizationalUnit,
    idCase,
    summary,
    patients,
    perPatient,
  ) {
    return this.axios.post(`/subcases/`, {
      IdOrganizationalUnit: idOrganizationalUnit,
      IdCase: idCase,
      Name: caseName,
      File: file,
      SummaryFile: summary,
      Patients: patients,
      PerPatients: perPatient,
    });
  }

  PutSubcaseState(idSubcase, status) {
    return this.axios.put(`/subcases/${idSubcase}/status`, {
      Status: status,
    });
  }

  PutSubcaseOwner(idSubcase, newOwner) {
    return this.axios.put(`/subcases/${idSubcase}/owner`, {
      NewOwner: newOwner,
    });
  }
  getUserCollaboration(idCase) {
    return this.axios.get(`/users/cases/${idCase}/collaboration`);
  }
  PostUserCollaboration(idCase, userName) {
    return this.axios.post(`/user/${userName}/cases/${idCase}/collaboration`);
  }
  DeleteUserCollaboration(idCase, userName) {
    return this.axios.delete(`/user/${userName}/cases/${idCase}/collaboration`);
  }

  // Case template services

  getCaseTemplate(idCaseTemplate) {
    return this.axios.get(`/case-templates/${idCaseTemplate}`);
  }

  getCaseTemplateGeneralSummations(idCaseTemplate) {
    return this.axios.get(
      `/case-template/${idCaseTemplate}/general-summations`,
    );
  }

  getCaseTemplatesOU(idOrganizationalUnit) {
    return this.axios.get(
      `/case-templates?idOrganizationalUnit=${idOrganizationalUnit}`,
    );
  }

  postCaseTemplateRow(
    idCaseTemplate,
    { rowName, idMasterForm, blocked, index },
  ) {
    return this.axios.post(`/case-templates/${idCaseTemplate}/rows`, {
      rowName,
      idMasterForm,
      blocked,
      index,
    });
  }

  updateCaseTemplateRow(
    idCaseTemplate,
    idTemplateRow,
    { name, index, idMasterForm, blocked },
  ) {
    return this.axios.put(
      `/case-templates/${idCaseTemplate}/rows/${idTemplateRow}`,
      {
        name,
        idMasterForm,
        blocked,
        index,
      },
    );
  }

  deleteCaseTemplateRow(idCaseTemplate, idTemplateRow) {
    return this.axios.delete(
      `/case-templates/${idCaseTemplate}/rows/${idTemplateRow}`,
    );
  }

  getCaseTemplatesbyOU(enabled = true, idOrganizationalUnit) {
    return this.axios.get(`/case-templates`, {
      params: {
        enabled,
        idOrganizationalUnit,
      },
    });
  }

  postNewCaseTemplates(caseTemplateName, idOrganizationalUnit) {
    return this.axios.post(`/case-templates`, {
      caseTemplateName,
      idOrganizationalUnit,
    });
  }

  putCaseTemplates(idCaseTemplate) {
    return this.axios.put(`/case-templates/${idCaseTemplate}`);
  }

  putCaseTemplatesEnabled(idCaseTemplate) {
    return this.axios.put(`/case-templates/${idCaseTemplate}/enable`);
  }

  deleteCaseTemplates(idCaseTemplate) {
    return this.axios.delete(`/case-templates/${idCaseTemplate}`);
  }

  getStatus() {
    return this.axios.get(`/status`);
  }

  createCategory(idSalaryType) {
    return this.axios.post(
      `/administrative-cost/categories/categories/salary-type/${idSalaryType}`,
      {
        name: "",
      },
    );
  }

  updateCategory(idSalaryType, idCategory, name) {
    return this.axios.put(
      `/administrative-cost/categories/categories/${idCategory}/salary-type/${idSalaryType}`,
      {
        name,
      },
    );
  }

  deleteCategory(idSalaryType, idCategory) {
    return this.axios.delete(
      `/administrative-cost/categories/categories/${idCategory}/salary-type/${idSalaryType}`,
    );
  }

  getSalaryCategories(idSalaryType) {
    return this.axios.get(
      `/administrative-cost/categories/categories/salary-type/${idSalaryType}`,
    );
  }

  createSeniority(idSalaryType) {
    return this.axios.post(
      `/administrative-cost/seniority/salary-type/${idSalaryType}`,
      {
        name: "",
      },
    );
  }

  deleteSeniority(idSalaryType, idSeniority) {
    console.log(idSalaryType);
    return this.axios.delete(
      `/administrative-cost/seniority/seniority/${idSeniority}/salary-type/${idSalaryType}`,
    );
  }

  updateSeniority(idSalaryType, idSeniority, description) {
    return this.axios.put(
      `/administrative-cost/seniority/${idSeniority}/salary-type/${idSalaryType}`,
      {
        name: description,
      },
    );
  }

  postSalaryType(name, workingHours) {
    return this.axios.post(`/salaries/types`, {
      salaryType: name,
      yearlyWorkingHours: workingHours,
    });
  }

  putSalaryType(idSalaryType, name, workingHours) {
    return this.axios.put(`/salaries/types/${idSalaryType}`, {
      salaryType: name,
      yearlyWorkingHours: workingHours,
    });
  }

  getSalaryType(idSalaryType) {
    return this.axios.get(`/salaries/types/${idSalaryType}`);
  }

  deleteSalaryType(idSalaryType) {
    return this.axios.delete(`/salaries/types/${idSalaryType}`);
  }

  postSalaryValue(idSalaryType, idCategory, idSeniority, salary) {
    return this.axios.post(
      `/administrative-cost/values/category/${idCategory}/seniority/${idSeniority}/salary-type/${idSalaryType}`,
      {
        salary: salary,
      },
    );
  }
}
