import axios from "@/services/api";

const state = {
  materialCostsTypes: [],
  materialCostType: null,
  costTypeName: null,
  materialCostCategories: [],
  materialCostCategory: null,
  materialCostClasses: [],
  materialCostClass: null,
  materialCostValues: [],
  materialCostValue: null,
  message: "",
};

const getters = {
  materialCostsTypes: (state) => state.materialCostsTypes,
  materialCostType: (state) => state.materialCostType,
  costType: (state) => state.costType,
  materialCostCategories: (state) => state.materialCostCategories,
  materialCostCategory: (state) => state.materialCostCategory,
  materialCostClasses: (state) => state.materialCostClasses,
  materialCostClass: (state) => state.materialCostClass,
  materialCostValues: (state) => state.materialCostValues,
  materialCostValue: (state) => state.materialCostValue,
  message: (state) => state.message,
};

const actions = {
  getMaterialCostsTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/material/costs-types").then(
        (response) => {
          commit("setMaterialCostsTypes", response.data);
          resolve(response);
        },
        (error) => {
          commit("setMaterialCostsTypes", []);
          reject(error);
        },
      );
    });
  },
  getCostType({ commit }, payload) {
    let { idMaterialCostType } = payload;
    return new Promise((resolve, reject) => {
      axios.get(`/material/costs-type/${idMaterialCostType}`).then(
        (response) => {
          commit("setMaterialCostType", response.data);
          resolve(response);
        },
        (error) => {
          commit("setMaterialCostType", null);
          reject(error);
        },
      );
    });
  },
  putCostType({ commit }, payload) {
    let { idMaterialCostType, costTypeName } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(`/material/cost-type/${idMaterialCostType}/data`, { costTypeName })
        .then(
          (response) => {
            commit("setCostType", response.data);
            resolve(response);
          },
          (error) => {
            reject(error.response.data.text);
          },
        );
    });
  },
  postCostType({ commit }, payload) {
    let { costTypeName } = payload;
    return new Promise((resolve, reject) => {
      axios.post(`/material/costs/types`, { costTypeName }).then(
        (response) => {
          commit("appendCostType", response.data.object);
          resolve(response);
        },
        (error) => {
          reject(error.response.data.text);
        },
      );
    });
  },
  deleteCostType({ commit }, payload) {
    let { idMaterialCostType } = payload;
    return new Promise((resolve, reject) => {
      axios.delete(`/material/cost-type/${idMaterialCostType}/status`).then(
        (response) => {
          commit("eraseCostType", idMaterialCostType);
          resolve(response);
        },
        (error) => {
          reject(error.response.data.text);
        },
      );
    });
  },
  getCostCategory({ commit }, payload) {
    let { idMaterialCostType } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/material-cost-categories/material-cost-type/${idMaterialCostType}`,
        )
        .then(
          (response) => {
            commit("setMaterialCostCategories", response.data);
            resolve(response);
          },
          (error) => {
            reject(error.response.data.text);
          },
        );
    });
  },
  putCostCategory({ commit }, payload) {
    let { idMaterialCostCategory, categoryName, idMaterialCostType } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(`/material-cost-categories/${idMaterialCostCategory}`, {
          categoryName,
          idMaterialCostType,
        })
        .then(
          (response) => {
            commit("setMaterialCostCategory", response.data.object);
            resolve(response);
          },
          (error) => {
            reject(error.response.data.text);
          },
        );
    });
  },
  postCostCategory({ commit }, payload) {
    let { idMaterialCostType, categoryName } = payload;
    return new Promise((resolve, reject) => {
      axios
        .post(`/material-cost-categories`, { categoryName, idMaterialCostType })
        .then(
          (response) => {
            commit("appendCostCategory", response.data.object);
            resolve(response);
          },
          (error) => {
            reject(error.response.data.text);
          },
        );
    });
  },
  deleteCostCategory({ commit }, payload) {
    let { idMaterialCostCategory } = payload;
    return new Promise((resolve, reject) => {
      axios.delete(`/material-cost-categories/${idMaterialCostCategory}`).then(
        (response) => {
          commit("eraseCostCategory", response.data);
          resolve(response);
        },
        (error) => {
          reject(error.response.data.text);
        },
      );
    });
  },
  getCostClasses({ commit }, payload) {
    let { idMaterialCostType } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/material-cost-classes/material-cost-type/${idMaterialCostType}`)
        .then(
          (response) => {
            commit("setCostClasses", response.data);
            resolve(response);
          },
          (error) => {
            reject(error.response.data.text);
          },
        );
    });
  },
  putCostClasses({ commit }, payload) {
    let { idMaterialCostClass, materialCostClassName } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(`/material-cost-classes/${idMaterialCostClass}`, {
          materialCostClassName,
        })
        .then(
          (response) => {
            commit("setMaterialCostClasses", response.data.object);
            resolve(response);
          },
          (error) => {
            reject(error.response.data.text);
          },
        );
    });
  },
  postCostClasses({ commit }, payload) {
    let { idMaterialCostType, className } = payload;
    return new Promise((resolve, reject) => {
      axios
        .post(`/material-cost-classes`, { className, idMaterialCostType })
        .then(
          (response) => {
            commit("appendCostClasses", response.data.object);
            resolve(response);
          },
          (error) => {
            reject(error.response.data.text);
          },
        );
    });
  },
  getCostValues({ commit }, payload) {
    let { idMaterialCostType } = payload;
    return new Promise((resolve, reject) => {
      axios
        .get(`/material-cost-categories/cost-type/${idMaterialCostType}/values`)
        .then(
          (response) => {
            commit("setCostValues", response.data);
            resolve(response);
          },
          (error) => {
            reject(error.response.data.text);
          },
        );
    });
  },
  putCostValue({ commit }, payload) {
    let { idMaterialCostCategory, idMaterialCostClass, value } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(
          `/material-cost-categories/${idMaterialCostCategory}/${idMaterialCostClass}/value`,
          { value },
        )
        .then(
          (response) => {
            commit("setCostValue", response.data.object);
            resolve(response);
          },
          (error) => {
            reject(error.response.data.text);
          },
        );
    });
  },
  deleteCostValue({ commit }, payload) {
    let { idMaterialCostCategory, idMaterialCostClass } = payload;
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/material-cost-categories/${idMaterialCostCategory}/${idMaterialCostClass}/value`,
        )
        .then(
          (response) => {
            commit("eraseCostValue", response.data);
            resolve(response);
          },
          (error) => {
            reject(error.response.data.text);
          },
        );
    });
  },
  getDownloadCostTypeCSV({ commit }, payload) {
    let { idMaterialCostType } = payload;
    return new Promise((resolve, reject) => {
      axios({
        url: `/material-costs/types/${idMaterialCostType}/csv`,
        method: "GET",
        responseType: "blob",
      }).then(
        (response) => {
          commit("setMessage", "");
          resolve(response);
        },
        (error) => {
          reject(error.response.data.text);
        },
      );
    });
  },
  putUploadCostTypeCSV({ commit }, payload) {
    let { idMaterialCostType, jsonFile } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(`/material-costs/types/${idMaterialCostType}/csv`, {
          JSON: jsonFile,
        })
        .then(
          (response) => {
            commit("setMessage", "");
            resolve(response);
          },
          (error) => {
            reject(error.response.data);
          },
        );
    });
  },
};

const mutations = {
  setMaterialCostsTypes(state, materialCostsTypes) {
    state.materialCostsTypes = materialCostsTypes;
  },
  setMaterialCostType(state, materialCostType) {
    state.materialCostType = materialCostType;
  },
  setCostType(state, materialCostType) {
    const index = state.materialCostsTypes.findIndex(
      (x) => x.idMaterialCostType === materialCostType.idMaterialCostType,
    );
    state.materialCostsTypes[index].costTypeName =
      materialCostType.costTypeName;
  },
  appendCostType(state, newCostType) {
    state.materialCostsTypes.push(newCostType);
  },
  eraseCostType: (state, idMaterialCostType) => {
    const index = state.materialCostsTypes.findIndex(
      (x) => x.idMaterialCostType == idMaterialCostType,
    );
    state.materialCostsTypes.splice(index, 1);
  },
  setMaterialCostCategories(state, materialCostCategories) {
    state.materialCostCategories = materialCostCategories;
  },
  setMaterialCostCategory(state, materialCostCategory) {
    const index = state.materialCostCategories.findIndex(
      (x) =>
        x.idMaterialCostCategory ===
        materialCostCategory.idMaterialCostCategory,
    );
    state.materialCostCategories[index].materialCostCategoryName =
      materialCostCategory.materialCostCategoryName;
  },
  appendCostCategory(state, newCostCategory) {
    state.materialCostCategories.push(newCostCategory);
  },
  eraseCostCategory: (state, idMaterialCostCategory) => {
    const index = state.materialCostCategories.findIndex(
      (x) => x.idMaterialCostCategory == idMaterialCostCategory,
    );
    state.materialCostCategories.splice(index, 1);
  },
  setCostClasses(state, materialCostClasses) {
    state.materialCostClasses = materialCostClasses;
  },
  setMaterialCostClasses(state, materialCostClass) {
    const index = state.materialCostClasses.findIndex(
      (x) => x.idMaterialCostClass === materialCostClass.idMaterialCostClass,
    );
    state.materialCostClasses[index].materialCostClassName =
      materialCostClass.materialCostClassName;
  },
  appendCostClasses(state, newCostClass) {
    state.materialCostClasses.push(newCostClass);
  },
  setCostValues(state, materialCostValues) {
    state.materialCostValues = materialCostValues;
  },
  setCostValue(state, materialCostValue) {
    const index = state.materialCostValues.findIndex(
      (x) =>
        x.idMaterialCostCategory === materialCostValue.idMaterialCostCategory,
    );
    state.materialCostValues[index].value = materialCostValue.value;
  },
  eraseCostValue: (state, idMaterialCostCategory) => {
    const index = state.materialCostCategories.findIndex(
      (x) => x.idMaterialCostCategory == idMaterialCostCategory,
    );
    state.materialCostCategories.splice(index, 1);
  },
  setMessage: (state, message) => {
    state.message = message;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
