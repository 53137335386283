/* eslint-disable */
import Oidc from 'oidc-client';

var linkClient = new Oidc.UserManager({
  userStore: new Oidc.WebStorageStateStore({ store: sessionStorage }),
  authority: process.env.VUE_APP_CLINICALSITE_URL,
  client_id: 'studget',
  redirect_uri: window.location.origin + '/UserProfile',
  response_type: 'code',
  scope: 'openid profile email affiliations',
  post_logout_redirect_uri: window.location.origin + '/login',
  silent_redirect_uri: window.location.origin,
  accessTokenExpiringNotificationTime: 10,
  automaticSilentRenew: false,
  mergeClaims: true,
  metadata: {
    issuer: process.env.VUE_APP_CLINICALSITE_URL,
    authorization_endpoint: process.env.VUE_APP_CLINICALSITE_URL + "/client/auth",
    token_endpoint: process.env.VUE_APP_CLINICALSITE_URL + "/api/oauth-token",
    userinfo_endpoint: process.env.VUE_APP_CLINICALSITE_URL + "/api/userinfo"
  }
})

var client = new Oidc.UserManager({
  userStore: new Oidc.WebStorageStateStore({ store: sessionStorage }),
  authority: process.env.VUE_APP_CLINICALSITE_URL,
  client_id: 'studget',
  redirect_uri: window.location.origin + '/login/callback',
  response_type: 'code',
  scope: 'openid profile email affiliations',
  post_logout_redirect_uri: window.location.origin + '/login',
  silent_redirect_uri: window.location.origin,
  accessTokenExpiringNotificationTime: 10,
  automaticSilentRenew: false,
  mergeClaims: true,
  metadata: {
    issuer: process.env.VUE_APP_CLINICALSITE_URL,
    authorization_endpoint: process.env.VUE_APP_CLINICALSITE_URL + "/client/auth",
    token_endpoint: process.env.VUE_APP_CLINICALSITE_URL + "/api/oauth-token",
    userinfo_endpoint: process.env.VUE_APP_CLINICALSITE_URL + "/api/userinfo"
  }
})

Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.ERROR;

client.events.addAccessTokenExpired(function () {
  client.removeUser().catch(function (err) {
    console.log(err)
  })
});

client.events.addUserSignedOut(function () {
  client.signoutRedirect().catch(function (err) {
    console.log(err)
  })
});

export default class OIDCService {

  // Get the user who is logged in
  getUser() {
    let self = this
    return new Promise((resolve, reject) => {
      client.getUser().then(function (user) {
        if (user == null) {
          self.signIn()
          return resolve(null)
        } else {
          return resolve(user)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  getLinkedUser() {
    let self = this;
    return new Promise((resolve, reject) => {
      linkClient.getUser().then(function (user) {
        if (user == null) {
          self.linkedSignIn()
          return resolve(null)
        } else {
          return resolve(user)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  // Check if there is any user logged in
  getSignedIn() {
    return new Promise((resolve, reject) => {
      client.getUser().then(function (user) {
        if (user == null) {
          return resolve(false)
        } else {
          return resolve(true)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  signinRedirect(url) {
    return client.signinRedirectCallback(url)
  }

  // Redirect of the current window to the authorization endpoint.
  signIn() {
    client.signinRedirect().catch(function (err) {
      console.log(err)
    })
  }

  linkedSignIn() {
    linkClient.signinRedirect().catch(function (err) {
      console.log(err)
    })
  }

  // Redirect of the current window to the end session endpoint
  signOut() {
    client.removeUser().then(() => {
      client.clearStaleState()
    }).catch(function (err) {
      console.log(err)
    })
  }
}