import { inject } from "vue";
import axios from "@/services/api";

const session = inject("$vsession");
const defaultItems = {
  MainCategories: [],
  CostCategories: [],
  TimeCategories: [],
  ResourcePool: { Resources: [] },
  Summations: [],
  Parameters: [],
  GeneralModification: {
    CostCategories: [],
    TimeCategories: [],
    MainCategories: [],
    Summations: [],
  },
};

const defaultGeneralItems = {
  MainCategories: [],
  CostCategories: [],
  TimeCategories: [],
  ResourcePool: { Resources: [] },
  Summations: [],
  Parameters: [],
};

const state = {
  masterForms: [],
  generalMasterForms: [],
  items: defaultItems,
  generalItems: defaultGeneralItems,
  summaryFile: null,
  surchargeTypes: [],
  idMasterForm: 0,
  nameMasterForm: "",
  busy: false,
  busyPost: false,
  busyGet: false,
  selectedMasterForm: {},
  summationEdit: false,
  idOrganizationalUnit: 0,
  allowPerPatients: null,
  editable: false,
  parent: null,
};

const getters = {
  items: (state) => state.items,
  busy: (state) => state.busy,
  busyGet: (state) => state.busyGet,
  busyPost: (state) => state.busyPost,
  generalItems: (state) => state.generalItems,
  summaryFile: (state) => state.summaryFile,
  SurchargeTypes: (state) => state.surchargeTypes,
  idMasterForm: (state) => state.idMasterForm,
  nameMasterForm: (state) => state.nameMasterForm,
  masterForms: (state) => state.masterForms,
  selectedMasterForm: (state) => state.selectedMasterForm,
  generalMasterForms: (state) => state.generalMasterForms,
  summationEdit: (state) => state.summationEdit,
  parameters: (state) => state.items.Parameters,
  parentParameters: (state) =>
    state.generalItems?.Parameters ? state.generalItems?.Parameters : [],
  idOrganizationalUnit: (state) => state.idOrganizationalUnit,
  allowPerPatients: (state) => state.allowPerPatients,
  editable: (state) => state.editable,
  parent: (state) => state.parent,
};

const actions = {
  getMasterForms({ commit }) {
    commit("setBusy", true);
    return new Promise((resolve, reject) => {
      axios
        .get(`/master-forms`)
        .then(
          (response) => {
            commit("setMasterForms", response.data);
            resolve(response);
          },
          (error) => {
            commit("setMasterForms", []);
            reject(error);
          },
        )
        .finally(() => {
          commit("setBusy", false);
        });
    });
  },
  removeMasterForms({ commit }, { idMasterForm }) {
    commit("setBusy", true);
    return new Promise((resolve, reject) => {
      axios
        .delete(`/master-forms/${idMasterForm}`)
        .then(
          (response) => {
            commit("deleteMasterForms", idMasterForm);
            resolve(response);
          },
          (error) => {
            reject(error);
          },
        )
        .finally(() => {
          commit("setBusy", false);
        });
    });
  },
  postMasterForms(
    { commit, dispatch },
    { api, idOrganizationalUnit, name, idParent },
  ) {
    commit("setBusyPost", true);
    return new Promise((resolve, reject) => {
      axios
        .post(`/master-forms`, { idOrganizationalUnit, name, idParent })
        .then(
          (response) => {
            dispatch("getMasterForms", { session, api });
            resolve(response);
          },
          (error) => {
            console.log("Master form error: ", error);
            reject(error);
          },
        )
        .finally(() => {
          commit("setBusyPost", false);
        });
    });
  },
  getMasterForm({ commit }, { idMasterForm }) {
    return new Promise((resolve, reject) => {
      axios.get(`/master-forms/${idMasterForm}`).then(
        (response) => {
          let general = JSON.parse(response.data.general);
          let specific = JSON.parse(response.data.specific);
          let summaryFile = JSON.parse(response.data.summaryFile);
          commit("setItems", specific);
          commit("setGeneralItems", general);
          commit("setSummaryFile", summaryFile);
          commit("setIdMasterForm", response.data.idMasterForm);
          commit("setIdOU", response.data.idOrganizationalUnit);
          commit("setNameMasterForm", response.data.name);
          commit("setSummationEdit", response.data.summationEdit);
          commit("setAllowPerPatients", response.data.allowPerPatients);
          commit("setEditable", response.data.editable);
          commit("setParent", response.data.parent);
          resolve(response);
        },
        (error) => {
          console.log("Master form error: ", error);
          commit("setItems", defaultItems);
          commit("setGeneralItems", defaultGeneralItems);
          commit("setSummaryFile", null);
          reject(error);
        },
      );
    });
  },
  setSelectedMasterForm({ commit }, selectedMasterForm) {
    commit("setSelectedMasterForm", selectedMasterForm);
  },
  UpdateTemplate({ commit }, payload) {
    let { idMasterForm, file, summaryFile, name, allowPerPatients } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(`/master-forms/${idMasterForm}`, {
          name,
          file,
          summaryFile,
          allowPerPatients,
        })
        .then(
          (response) => {
            let general = JSON.parse(response.data.general);
            let specific = JSON.parse(response.data.specific);
            let summaryFile = JSON.parse(response.data.summaryFile);
            commit("setItems", specific);
            commit("setGeneralItems", general);
            commit("setSummaryFile", summaryFile);
            commit("setIdMasterForm", response.data.idMasterForm);
            commit("setIdOU", response.data.idOrganizationalUnit);
            commit("setNameMasterForm", response.data.name);
            commit("setSummationEdit", response.data.summationEdit);
            commit("setAllowPerPatients", response.data.allowPerPatients);
            commit("setEditable", response.data.editable);
            commit("setParent", response.data.parent);
            resolve(response);
          },
          (error) => {
            console.log("Saving error: ", error);
            reject(error);
          },
        );
    });
  },
  async getSurchargeTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/Customer/Surcharge/Definitions?enabled=true`).then(
        (response) => {
          commit("setSurchargeTypes", response.data);
          resolve(response);
        },
        (error) => {
          console.log("Master form error: ", error);
          commit("setSurchargeTypes", []);
          reject(error);
        },
      );
    });
  },
  getGeneralMasterForms({ commit }) {
    commit("setBusyGet", true);
    return new Promise((resolve, reject) => {
      axios
        .get(`/master-forms/general`)
        .then(
          (response) => {
            commit("setGeneralMasterForms", response.data);
            resolve(response);
          },
          (error) => {
            commit("setGeneralMasterForms", []);
            reject(error);
          },
        )
        .finally(() => {
          commit("setBusyGet", false);
        });
    });
  },
  postParameter({ commit }, { idParameter }) {
    return new Promise((resolve, reject) => {
      try {
        commit("setParameter", idParameter);
        resolve();
      } catch {
        reject();
      }
    });
  },
  deleteParameter({ commit }, { idParameter }) {
    return new Promise((resolve, reject) => {
      try {
        commit("deleteParameter", idParameter);
        resolve();
      } catch {
        reject();
      }
    });
  },
  putParameters({ commit }, { idMasterForm, parameters }) {
    commit("setBusyGet", true);
    return new Promise((resolve, reject) => {
      axios
        .put(`/parameters/master-form/${idMasterForm}`, parameters)
        .then(
          (response) => {
            resolve(response);
          },
          (error) => {
            console.log("parameter update error: ", error);
            reject(error);
          },
        )
        .finally(() => {
          commit("setBusyGet", false);
        });
    });
  },
};

const mutations = {
  setAllowPerPatients(state, allowPerPatients) {
    state.allowPerPatients = allowPerPatients;
  },
  setBusy(state, busy) {
    state.busy = busy;
  },
  setBusyPost(state, busyPost) {
    state.busyPost = busyPost;
  },
  setBusyGet(state, busy) {
    state.busyGet = busy;
  },
  setSelectedMasterForm(state, selectedMasterForm) {
    state.selectedMasterForm = selectedMasterForm;
  },
  setGeneralItems(state, fileInfo) {
    state.generalItems = fileInfo;
  },
  setItems(state, fileInfo) {
    state.items = fileInfo;
  },
  setSurchargeTypes: (state, surcharges) => {
    surcharges.push({
      idSurcharge: 0,
      surchargeName: "None (0%)",
    });
    state.surchargeTypes = surcharges;
  },
  setSummaryFile: (state, caseSummary) => {
    const convertArrayToObject = (array, key) => {
      const initialValue = {};
      return array.reduce((obj, item) => {
        return {
          ...obj,
          [item[key]]: item,
        };
      }, initialValue);
    };
    state.summaryFile = convertArrayToObject(caseSummary, "Id");
  },
  setIdMasterForm: (state, idMasterForm) => {
    state.idMasterForm = idMasterForm;
  },
  setIdOU: (state, idOU) => {
    state.idOrganizationalUnit = idOU;
  },
  setNameMasterForm: (state, nameMasterForm) => {
    state.nameMasterForm = nameMasterForm;
  },
  setMasterForms: (state, masterForms) => {
    state.masterForms = masterForms;
  },
  setGeneralMasterForms: (state, masterForms) => {
    state.generalMasterForms = masterForms;
  },
  setSummationEdit: (state, summationEdit) => {
    state.summationEdit = summationEdit;
  },
  setEditable: (state, editable) => {
    state.editable = editable;
  },
  setParent: (state, parent) => {
    state.parent = parent;
  },
  deleteMasterForms: (state, idMasterForm) => {
    state.masterForms = state.masterForms.filter(
      (mf) => mf.idMasterForm != idMasterForm,
    );
  },
  setParameter: (state, parameter) => {
    state.items.Parameters.push(parameter);
  },
  deleteParameter: (state, parameter) => {
    state.items.Parameters = state.items.Parameters.filter(
      (param) => param.idParameter !== parameter,
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
